<template>
  <div>
    <div class="bg" />
    <div class="container">
      <img src="@/assets/user-photo.png" class="photo" />
      <div class="content">
        <div class="usernames">姓名:{{username}}</div> 
        <div class="tel"> 手机号:{{user_tel}}</div>
        <div class="message">想成为"{{doorname}}"的门卫</div>
        <div class="button primary" @click="submit">设定为门卫</div>
        <div class="button primary" @click="cancel">设定为访客</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserinfo } from '@/utils';
import { Toast, Dialog } from 'vant';
import qs from 'qs';
export default {
  async created() {
    let { openid, doorname,types,stiecode,client_id,user_tel,username,messageid } = this.$route.query;
     this.openid = openid;
     this.doorname = doorname;
     this.types = types;
     this.stiecode = stiecode;
     this.client_id = client_id;
     this.user_tel = user_tel;
     this.username = username;
     this.messageid = messageid;
  },
  data() {
    return {
      openid: '',
      doorname: '',
      types: '',
      stiecode: '',
      client_id: '',
      user_tel:'',
      username:'',
      messageid:''
    };
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async submit() {
      Dialog.confirm({
        message: '确定设定为门卫吗?'
      }).then(async () => {
       let { data } = await this.axios.post(
        '/ctid/tlService/spmw',
        qs.stringify({
          openid:this.openid,
          messageid: this.messageid,
          stie_code: this.stiecode,
          client_id: this.client_id
        })
      );
      if (data.flag === '0') {
        this.$router.replace('/succ');
      } else {
        Dialog({ message: data.message });
      }
      });
      
    }
  }
};
</script>

<style lang="less" scoped>
.photo {
  width: 166px;
  height: 189px;
  display: block;
  margin: 66px auto 0;
}
.content {
  margin-top: 66px;
}
.name {
  text-align: center;
  color: #fff;
  font-size: 44px;
  line-height: 60px;
  margin-top: 14px;
}
.username {
  height: 64px;
  line-height: 64px;
  overflow: hidden;
  .icon {
    width: 64px;
    height: 64px;
    float: left;
    display: block;
  }
  .text {
    margin-left: 22px;
    float: left;
  }
}
.usernames {
  overflow: hidden;
  margin: 50px auto;
  text-align: center;
}
.message {
  overflow: hidden;
  margin: 50px auto;
  text-align: center;
}
.tel {
  overflow: hidden;
  margin: 50px auto;
  text-align: center;
}
.button {
  width: 45%;
  height: 88px;
  text-align: center;
  display: inline-block;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  margin-left: 20px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(0, 98, 255, 1) 0%, rgba(99, 52, 255, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 54px;
  }
}
</style>
